@import url('https://bootswatch.com/5/lumen/bootstrap.min.css ');
body{
  font-family: "Inter",sans-serif;
}
.header-wrapper{
  padding: 15px 30px;
    background-color: rgba(255,255,255,0.72);
    /* background-color: #FFD8B8; */
    display: flex;
    align-items: center;
    backdrop-filter: saturate(180%) blur(20px);
    position: fixed;
    width: 100%;
    justify-content: center;
    top: 0;
    z-index: 99;
}
.t-header{
  background-color: rgba(255,255,255,0.72);
  backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  top: 78px;
}
.logo{
  height: 40px;
}
/* .container{
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
@media(max-width:678px){
  .container{
    padding: 15px;
  }
} */
.text-home{
  max-width: 600px;
  margin: auto;
  margin-top: 30px;
  font-size: 18px;
  text-align: justify;
}
.content-tabs, .tabs-content{
  max-width: 500px;
  margin: auto;
  text-align: center;
}
.tabs-content{
  max-width: 600px;
  margin:auto;
}
.form-group{
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-right{
  text-align: right;
}
.mr-2{
  margin-right: 10px;
}
.mb-2{
  margin-bottom: 15px;
}
.question{
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DAE9FF;
}
.text-content>p{
  margin-bottom: 0;
  align-items: center;
  display: flex;
}
.text-action{
    width: 82px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    justify-content: space-between;
    min-width: 82px;
}
input[type="radio"]{
  height: 20px;
  width: 20px;
  padding-right: 10px;
}
.question strong{
  margin-right: 12px;
}
.t-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.t-text-actions{
  padding-right: 10px;
  width: 82px;
  justify-content: space-between;
  display: flex;
}
.card-start{
  padding: 15px 25px;
  border: none;
}
.question.done{
  background: #f4fbfa;
}
.progress-bar{
  transition: all 0.3s ease-in-out;
}
.progress{
  top: 70px;
  width: 100%;
  height: 8px;
  position: fixed;

}
.progress-bar-animated{
  animation: 1s linear infinite progress-bar-stripes!important;
}
.header-content a{
  text-decoration: none;
  color: inherit;
}
.card{
  background-color: rgba(255,255,255,0.72);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 4px -1px 15px rgb(0 0 0 / 20%);
}
.ctx{
  background-image: url('./assets/bgx.png');
  background-position-y: 100%;
  height: 500px;
  padding: 20px;
  padding-top: 100px;
}
.start-header{
  text-align: center;
  color: #FFF;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.form-control{
  font-size: 18px;
}
label{
  font-size: 17px;
}
.form-control.email{
  text-align: center;
}
.test-content .container{
  margin-top: 100px;
}
.header-wrapper.more{
  margin-top: -70px;
}
.more.progress{
  top: 0px!important;
}
.more.t-header{
  top: 8px;
}
.more{
  transition: all 0.3s ease-in-out;
}
.t-text-content{
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.t-text-content p{
  margin-bottom: 0;
}
@media(max-width:768px){
  .t-header.more{
    background: #FFD8B8;
  }
}
@media(min-width:768px){
  .btn.btn-success:hover{
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }
}
.sub_heading{
  background: aliceblue;
  display: block;
  padding: 5px 20px;
}
.card-header{
  background: #FFF;
}
.page{
  margin: auto;
  margin-top: 70px;
  max-width: 1100px;
}
.page .card{
  box-shadow: none;
}
